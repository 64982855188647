*{
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    padding-top: env(safe-area-inset-top, 20px);  /* 20px est une valeur de repli (fallback) */
    padding-right: env(safe-area-inset-right, 0);
    padding-bottom: env(safe-area-inset-bottom, 0);
    padding-left: env(safe-area-inset-left, 0);
    max-height: 100vh;
    overscroll-behavior: none;


}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.like-icon {
  transition: transform 0.3s ease; /* Ajoute une transition de 0.3s pour l'échelle */
}

.like-icon.clicked {
  transform: scale(1.2); /* Applique un zoom de 1.5 fois lorsqu'un utilisateur clique sur l'icône */
  fill: red;
  animation: pulse 0.3s linear;
}

@keyframes pulse {
  0%{
    filter: drop-shadow(0px 0px 0px red);
  }

  0%{
    filter: drop-shadow(0px 0px 10px red);
  }

  100%{
    box-shadow: 0px 0px 0px red;

  }
}

.home{
  text-decoration: none;
  color: black;
  transition: 0.2s;
}
.home:hover{
  color: rgb(165, 165, 165);
  transition: 0.2s;
}

.liste{
  display: flex;
  overflow-y: scroll;
}

.nbr-like{
  width: 19px;
  text-align: center;
  color: white;
  font-size: small;
}

footer{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  background: #2f2f33;
  height: 10vh;
  bottom: 0px;
  width: 100%;
  left: 0px;
  margin-top: 10px;
  position: fixed;
}

.twitch,.tik-tok,.insta {
  background: whitesmoke;
  border-radius: 250px;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
  transition:0.3s  ease-in-out;
}

.twitch:hover {
  background: #9146ff;
    filter: drop-shadow(0px 2px 4px rgba(161, 161, 161, 0.295));

}

.tik-tok:hover {
  background: #21211d;
  filter: drop-shadow(0px 2px 4px rgba(161, 161, 161, 0.199));

}

.insta:hover {
  background: #C13584;
    filter: drop-shadow(0px 2px 4px rgba(161, 161, 161, 0.295));

}

.twitch:hover svg {
  fill: white;
  transition: 0.3s ease-in-out;
}

.tik-tok:hover svg {
  fill: white;
  transition: 0.3s ease-in-out;
}

.insta:hover svg {
  fill: white;
  transition: 0.3s ease-in-out;
}






/* end footer  */






.host{
  width: auto;
  display:flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.host img{
  width: 50px;
}

h1{
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

nav{
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

nav ul {
  display: flex;
  gap: 15px;
  align-items: center;
}
  

.btn-menu, .btn-menu-dc{
  list-style-type: none;
}


.btn-menu a{
  text-decoration: none;
  border-radius: 5px;
  box-shadow: 2px 4px 10px rgba(102, 88, 88, 0.089);
  color: #1d1d21;
  transition: 0.3s;
  padding: 10px;
  font-size: 14px;
}
.btn-menu-dc a{
  text-decoration: none;
  border-radius: 5px;
  box-shadow: 2px 4px 10px rgba(102, 88, 88, 0.089);
  color: #1d1d21;
  transition: 0.3s;
  padding: 10px;
  font-size: 14px;
  background: red;
  color: white;
}


footer img {
  width: 50px;
}



.btn-menu a:hover{
  box-shadow: 2px 4px 10px rgba(102, 88, 88, 0.281);
  background: #1d1d21;
  color: #e0e0e0;
}

.btn-menu-dc a:hover{
  box-shadow: 2px 4px 10px rgba(102, 88, 88, 0.281);
  background: #1d1d21;
  color: #e0e0e0;
}

header{
  display: flex;
  justify-content: space-between;
  padding: 5px 20px;
  height: 10vh;
  width: 100%;
}

.btn-profile{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn-profile img{
  width: 60px;
  border-radius: 250px;
}


nav button{
  border: none;
  background: none;
}

.btn-disconnect{
  cursor: pointer;
  font-size: 12px;
  transition: 0.3s;
  padding: 5px;
  border-radius: 5px;
}
.btn-disconnect:hover{
  background-color: rgb(209, 0, 0);
  color: #e0e0e0;
}

.menu-burger{
  display: none;
}





/* end header */




section{
  position: absolute;
  top: 10vh;
  left: 0px;
  height: 80vh;
  width: 100%;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
}

.liste-genres{
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  padding: 25px;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}

.card-genres{
  height: 150px;
  width: 250px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 15px;
  border-radius: 5px;
  background-size: cover;
  background-position: center;
  color: white;
  letter-spacing: 3px;
  position: relative;
  box-shadow: inset 0px -35px 20px rgba(0, 0, 0, 0.356);
}

.overlay{
  background: rgba(61, 61, 61, 0.295);
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  background-size: contain;
  background-position: center bottom 0px;
  background-repeat: no-repeat;
  backdrop-filter: blur(1px);
  height: 0px;
  transition: 0.4s;
  filter: drop-shadow(0px 0px 10px #1d1d21);
  filter: saturate(150%);
  border-radius: 5px;
}

.card-genres h3 {
  position: absolute;
  bottom: 5px;
  transition: 0.4s;
}

.card-genres:hover .overlay{
  height: 100%;
}

.card-genres:hover h3{
  position: absolute;
  bottom: -25px;
  color: black;
}

h4{
  border-bottom: 1px solid #1d1d21;
  padding: 0px 0px 5px 0px;
}


  .btn-choose-game {
    text-decoration: none;
    border-radius: 5px;
    box-shadow: 2px 4px 10px rgba(102, 88, 88, 0.089);
    color: #1d1d21;
    transition: 0.3s;
    padding: 10px;
    font-size: 14px;
  }
  
  .btn-choose-game:hover{
    box-shadow: 2px 4px 10px rgba(102, 88, 88, 0.281);
    background: #1d1d21;
    color: #e0e0e0;
  }


  /* Style de la barre de défilement */
::-webkit-scrollbar {
  width: 5px; /* Largeur de la barre de défilement */
}

/* Style de la poignée (bare intérieur) */
::-webkit-scrollbar-thumb {
  background-color: #000; /* Couleur de la poignée */
  border-radius: 6px; /* Coins arrondis de la poignée */
}

/* Style de la piste (la "barre" en arrière-plan) */
::-webkit-scrollbar-track {
  background-color: #ccc; /* Couleur de la piste (barre en arrière-plan) */
  border-radius: 50px;
}

/* Désactiver les flèches de défilement */
::-webkit-scrollbar-button {
  display: none;
  scrollbar-width: thin;
  scrollbar-color: #ccc #000;
}



/* aventures css */






.card-game{
  height: 300px;
  width:200px;
  padding: 15px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  border-radius: 5px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 2px 4px 10px rgba(65, 60, 60, 0.26);
  position: relative;
  box-shadow: inset 0px 50px 20px rgba(0, 0, 0, 0.452);
}
.card-game:hover{
  transform: scale(0.98);
}

.card-game h3{
  position: absolute;
  top: 10px;
  left: 10px;
  mix-blend-mode: hard-light;
  color: white;
  width: 75%;
  font-size: small;
}

.liste-aventures{
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  width: 100%;
  height: auto;
  padding: 25px;
  justify-content: center;
  overflow-y: auto;
  margin-top: 25px;
}

.podium{
  height: 400px;
  display: flex;
  justify-content: center;
  position: relative;
}

.liste-jeux{
  width: 100%;
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.liste-jeux-xs{
  width: 80%;
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.card-1, .card-2, .card-3{
  width: 300px;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.363);
  text-align: center;
  color: white;
  box-shadow: inset 0px 50px 50px rgba(0, 0, 0, 0.671);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-1{
  height: 400px;
  z-index: 3;
}

.card-2{
  height: 350px;
  position: absolute;
  bottom: 0px;
  left: -280px;
  z-index: 2;
}

.card-3{
  height: 300px;
  position: absolute;
  bottom: 0px;
  right: -280px;
  z-index: 1;
}

.cover img{
  width: 50px;
  height: 80px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}

.liste-podium{
  width: 90%;
  overflow-y: auto;
}

table{
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  margin-top: 15px;
}

td{
  padding: 15px;
}

tbody tr{
  background: #e0e0e033;
  border-radius: 5px;
}


/* prochain jeu  */



.objectif{
  width: 100%;
  padding: 50px;
  display: flex;
  justify-content: center;
  position: relative;
}

progress{
  height: 70px;
  width: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.082));
}

.label-progress{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.btn-register{
  text-decoration: none;
  border-radius: 5px;
  box-shadow: 2px 4px 10px rgba(102, 88, 88, 0.089);
  color: #1d1d21;
  transition: 0.3s;
  padding: 5px 50px;
  font-size: 14px;
}

.btn-register:hover{
  box-shadow: 2px 4px 10px rgba(102, 88, 88, 0.281);
  background: #1d1d21;
  color: #e0e0e0;
}

.cadeau{
  width: 50%;
  height: 80%;
  background-size: cover;
  background-position: center;
  padding: 25px;
  position: relative;
  border-radius: 5px;
  box-shadow: 2px 4px 10px rgba(102, 88, 88, 0.281);
  margin-top: 25px;
}

.cadeau-xs{
  width: 50%;
  height: 80%;
  background-size: cover;
  background-position: center;
  padding: 25px;
  position: relative;
  border-radius: 5px;
  box-shadow: 2px 4px 10px rgba(102, 88, 88, 0.281);
  margin-top: 25px;
  margin-bottom: 25px;
}

.prix-cadeau{
  position: absolute;
  top: 0px;
  left: 0px;
  font-size: 2em;
  background-color: #1d1d218e;
  color: #e0e0e0;
  padding: 10px 30px;
  border-radius: 5px 0px 5px 0px;
}

.nbr-participant{
  position: absolute;
  right: 0px;
  bottom: 50px;
  background-color: #1d1d21;
  color: #e0e0e0;
  padding: 5px 100px;
  border-radius: 5px 0px 0px 5px;
}

form{
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 50%;
  padding: 50px;
  align-items: center;
}

.champ{
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 70%;
}

.champ input{
  padding: 10px;
  border-radius: 5px;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.521);
  box-shadow: 2px 4px 10px rgba(102, 88, 88, 0.116);
  transition: 0.3s;
}

.champ input:focus{
  background: #1d1d21;
  color: #e0e0e0;
}

.btn{
  border: none;
  background: none;
  width: 50%;
  cursor: pointer;
}

.link{
  text-decoration: none;
  font-style: italic;
  font-size: 0.9em;
  transition: 0.3s;
}
.link:hover{
  scale: 0.98;
}

.menu-admin{
  position: absolute;
  bottom: 0px;
  left: 0px;
  background: #1d1d21;
  height: 100%;
  width: 200px;
  color: #e0e0e0;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.menu-admin li {
  list-style-type: none;
  transition: 0.3s;
}

.menu-admin a {
  text-decoration: none;
  color: #e0e0e0;
}
.menu-admin li:hover{
  scale: 0.95;
}

.menu-admin ul{
  display: flex;
  flex-direction: column;
  gap: 15px;
}


.tirage, .gagnant{
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;
  font-size:x-large;
}

.btn-tirage{
  padding: 5px 50px;
}

.gagnant h3 {
  font-size: 2em;
}

.btn-twitch{
  background: #9146ff;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
    transition: 0.2s;

}

.btn-youtube{
  background: red;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
    transition: 0.2s;

}

.btn-inactive{
  background: grey;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
    transition: 0.2s;

}

.btn-inactive:hover, .btn-youtube:hover, .btn-twitch:hover{
  box-shadow: inset 0px 0px 10px black;
  transition: 0.2s;
}

.spinner{
  height: 100vh;
  width: 100vw;
  position: relative;
  text-align: center;
}

.spinner::after{
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border-color: black black white white;
  border-width: 5px;
  border-style: solid;
  animation: turn 1s linear infinite;
}

@keyframes turn {
0%{
  transform: rotate(0deg);
}
100%{
  transform: rotate(360deg);
}
}




@media (max-width:768px) {

  *{
    font-size: 12px;
  }

  [class] {
    font-size: 12px !important;
  }

  th{
    font-weight: lighter;
    width: 1%;
  }
  
  td{
    padding: 10px 5px;
  }

  .menu-burger{
    display: flex;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
  }
  
  
  .burger{
    height: 3px;
    width: 30px;
    background-color: #1d1d21;
    position: relative;
    cursor: pointer;
    transition: 0.4s;
  }
  .burger:before{
    content: '';
    width: 100%;
    height: 100%;
    background-color: #1d1d21;
    position: absolute;
    top: -10px;
    transition: 0.4s;
  
  }
  .burger:after{
    content: '';
    width: 100%;
    height: 100%;
    background-color: #1d1d21;
    position: absolute;
    top: 10px;
    transition: 0.4s;
  
  }
  
  

nav{
  display: none;
}


.menu{
  z-index: 10;
  width: 100%;
  position: absolute;
  top: 60px;
  left: 0px;
}

.burger-back-menu{
  background-color: #1d1d21;
  width: 100%;
  margin-top: 15px;
}

.burger-back-menu li{
  height: auto;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid #808080;
}

.burger-back-menu li a {
  text-decoration: none;
  color: #e0e0e0;
}

.menu-burger {
  cursor: pointer;
  transition: 0.4s;
}

.burger {
  width: 30px;
  height: 3px;
  background-color: #333;
  margin: 6px 0;
  transition: 0.4s;
}

.menu-burger.active .burger {
  background-color: transparent;
}

.menu-burger.active .burger::before {
  transform: rotate(-45deg) translate(-5px, 5px);
}

.menu-burger.active .burger::after {
  transform: rotate(45deg) translate(-5px, -5px);
}

.liste{
  flex-direction: column;
}

progress{
  width: 100%;
}

.liste-jeux{
  overflow: scroll;
}

.cadeau{
  width: 100%;
}

.card-1, .card-2, .card-3{
  width: 200px;
}

.card-1{
  height: 250px;
}

.card-2{
  height: 200px;
  left: -100px;
  bottom: 0px;
}
.card-2:hover{
  z-index: 10;
}

.card-3{
  height: 150px;
  left: 100px;
  bottom: 0px;
}
.card-3:hover{
  z-index: 10;
}

.podium{
  height: auto;
}

.liste-podium{
  font-size: 9px;
  width: 100%;
}

.menu-admin{
  width: 100px;
  position: relative;
}

form{
  width: 100%;
}

.champ{
  width: 100%;
}

.btn-register {
  width: 100%;
}

footer{
  height: 5vh;
}

.twitch, .insta, .tik-tok {
height: 4vh;
width: 8vw;
}

footer svg{
  height: 2vh;
}

section{
  justify-content: center;
  padding: 10px;
  height: 70vh;
}

.burger-back-menu li a {
  width: 100%;
  text-align: center;
}



.client{
  flex-direction: row;
  justify-content: space-between;
  padding: 0px;
  height: 80vh;
}

.client h2{
  width: 100%;
  text-align: center;
}

.client article h4{
  width: 90%;
  text-align: center;
}

.client article{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.client article button {
  width: 100%;
  padding: 0px;
}

.game-list{
  justify-content: flex-start;
}

@media (hover: none) and (pointer: coarse) {
  button:hover {
    background-color: #4CAF50; /* Maintenir la couleur de fond de base */
  }
}

}